export function youTubeEmbed() {
	if (document.querySelector('.m-yt-embed')) {
		var regExp = /(^|=|\/)([0-9A-Za-z_-]{11})(\/|&|$|\?|#)/;
		var youTubeWrapper = document.querySelectorAll('.m-yt-embed__wrap');

		for (var i = 0; i < youTubeWrapper.length; i++) {
			var video_id = youTubeWrapper[i].parentNode.dataset.embed.match(regExp)[2];
			var posterImage = document.createElement("img");

			if (!youTubeWrapper[i].classList.contains('m-yt-embed__wrap---initial')) {
				youTubeWrapper[i].classList.add('m-yt-embed__wrap---initial');
			}
			// hqdefault; mqdefault; sddefault; maxresdefault
			posterImage.setAttribute("src", "https://i.ytimg.com/vi/" + video_id + "/sddefault.jpg");
			posterImage.setAttribute("alt", "Preview Imgage:" + video_id);
			posterImage.setAttribute("title", video_id);
			posterImage.setAttribute("loading", "lazy");
			posterImage.setAttribute("class", "m-yt-embed__preview");

			youTubeWrapper[i].innerHTML = "";
			youTubeWrapper[i].appendChild(posterImage);

			youTubeWrapper[i].addEventListener("click", function() {
				var video_ident = this.parentNode.dataset.embed.match(regExp)[2];
				var iframe = document.createElement("iframe");
				iframe.setAttribute("frameborder", "0");
				iframe.setAttribute("allowfullscreen", "");
				iframe.setAttribute("allow", "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
				iframe.setAttribute("src", "https://www.youtube.com/embed/" + video_ident + "?rel=0&amp;showinfo=0&amp;autoplay=1&amp;playsinline=1&amp;enablejsapi=1");

				this.innerHTML = "";
				this.appendChild(iframe);
				this.parentNode.classList.add('m-yt-embed---loaded');
				this.classList.add('m-yt-embed__wrap---loaded');
				this.classList.remove('m-yt-embed__wrap---initial');
				this.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				});
			});
		}
	}
}